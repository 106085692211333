import React from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const styleCustom = {
  width: "4.1vw",
  height: "4.1vw",
  backgroundColor: "aquamarine",
  paddingTop: "6px",
  zIndex: "999"
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`${className}  bg-indigo-100 rounded-full shadow-md`}
      style={styleCustom}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      onClick={onClick}
      className={`${className} bg-indigo-100 rounded-full shadow-md`}
      style={styleCustom}
    />
  );
}

export default ({ slides, children }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <>
      <Slider {...settings} className="w-full">
        {slides.map((slide, i) => (
          <BackgroundImage
            className="xl:h-160 h-80 w-full bg-cover bg-center"
            fluid={slide.childImageSharp.fluid}
          />
        ))}
      </Slider>

      {/* <div className="p-2 lg:p-16 bg-blue-100">
        <div className="container w-1/2">
          <Slider {...settings} className="w-full">
            {slides.map((slide, i) => (
              <div key={`slide-${i}`} className="w-full h-full overflow-hidden">
                <Img
                  className="shadow-xl rounded-lg w-full h-full object-cover object-center"
                  alt="sonhe video"
                  fluid={slide.childImageSharp.fluid}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
    </>
  );
};
