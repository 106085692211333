import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { pathToLink } from "../helper";
import { has } from "lodash";

export default ({
  title,
  featuredImage,
  excerpt,
  slug,
  categories,
  className = "md:w-1/3"
}) => {
  return (
    <>
      <div className={`p-4 ${className}`}>
        <div className="h-full border-2 border-gray-200 rounded-lg overflow-hidden bg-white shadow-lg">
          {has(featuredImage, "node.localFile.childImageSharp.fluid") && (
            <Link to={`/${slug}/`}>
              <Img
                className="lg:h-48 md:h-36 w-full object-cover object-center"
                fluid={featuredImage.node.localFile.childImageSharp.fluid}
              />
            </Link>
          )}

          <div className="p-6">
            {categories &&
              categories.nodes &&
              categories.nodes.map(category => (
                <Link to={pathToLink(category.link)} key={category.link}>
                  <div className="tracking-widest uppercase text-xs title-font font-medium text-gray-500 my-2">
                    {category.name}
                  </div>
                </Link>
              ))}

            <h1 className="title-font text-lg font-medium text-gray-900 mb-3 hover:underline">
              <Link to={`/${slug}/`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: title
                  }}
                />
              </Link>
            </h1>

            {excerpt && (
              <p className="leading-relaxed mb-3">
                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
