import React from "react";
import { graphql, Link } from "gatsby";
import { get } from "lodash";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Card from "../components/Card";
import SEO from "../components/MetaSeo";
import Slider from "../components/Slider";
import SearchCourse from "../components/SearchCourse";
import Testimonials from "../components/Testimonials";
import WhatsappButton from "../components/WhatsappButton";

const Sonhe = ({ img }) => (
  <section className="text-gray-700 body-font">
    <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
          Você tem um <span className="text-underline">sonho?</span>
        </h1>
        <p className="mb-8 leading-relaxed ">
          Acreditamos que todas as pessoas possuem um sonho e se dispõem a
          concretizá-lo de alguma forma. É por isso que nós existimos. Somos
          sonhadores apaixonados por viagens e o nosso maior desejo é fazer
          parte do seu sonho, te ajudando no passo a passo, dando dicas,
          apoiando e vendo o seu progresso.
        </p>
        {/* <div className="flex justify-center">
        <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Button</button>
        <button className="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">Button</button>
      </div> */}
      </div>
      <a
        className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6"
        href="https://www.youtube.com/watch?v=f8ywc0-GuMs"
        target="_blank"
        rel="noreferrer"
      >
        <Img
          className="object-cover object-center rounded"
          alt="sonhe video"
          fluid={img.childImageSharp.fluid}
        />
      </a>
    </div>
  </section>
);

const Viaje = ({
  data: {
    viajeAustraliaImg,
    viajeCanadaImg,
    viajeIrlandaImg,
    viajeSchoolTacomaImg,
    viajeSchoolCanadaImg,
    viajeSchoolBerlinImg,
    viajeSchoolIdahoImg
  }
}) => {
  const Card = ({ name, img, className, to = "#" }) => (
    <Link className={className} to={to}>
      <div className="rounded-lg h-64 overflow-hidden">
        <Img
          className="object-cover object-center h-full w-full"
          alt="sonhe video"
          fluid={img.childImageSharp.fluid}
        />
      </div>
      <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
        {name}
      </h2>
      <p className="text-base leading-relaxed mt-2">Ensino Superior</p>
      {/* <a className="text-indigo-500 inline-flex items-center mt-3 underline hover:no-underline">Saiba Mais
        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a> */}
    </Link>
  );

  return (
    <section className="text-gray-700 body-font bg-blue-light">
      <div className="container px-5 pt-24 mx-auto">
        <div className="flex flex-col">
          <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
            <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter text-underline">
              Viaje
            </h2>
            <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
              Seja o intercâmbio de 01 mês ou educação superior, estamos
              preparados para melhor te atender. Viaje com a segurança de saber
              que os seus próximos passos estão em boas mãos. A gente lhe ajuda
              desde a escolha do país e curso até os processos com a escola. Na
              verdade, estamos sempre à sua disposição!
            </p>
          </div>
        </div>

        <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
          Melhores <span className="text-underline">Destinos</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mx-auto">
          <div className="p-4  sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <Img
                className="object-cover object-center h-full w-full"
                alt="sonhe video"
                fluid={viajeCanadaImg.childImageSharp.fluid}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Canadá
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Ensino Superior e Idiomas
            </p>
            <Link
              className="text-indigo-500 inline-flex items-center mt-3 underline hover:no-underline"
              to="/canada"
            >
              Saiba Mais
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
          <div className="p-4  sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <Img
                className="object-cover object-center h-full w-full"
                alt="sonhe video"
                fluid={viajeIrlandaImg.childImageSharp.fluid}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Irlanda
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Ensino Superior e Idiomas
            </p>
            <Link
              className="text-indigo-500 inline-flex items-center mt-3 underline hover:no-underline"
              to="/irlanda"
            >
              Saiba Mais
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
          <div className="p-4  sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
              <Img
                className="object-cover object-center h-full w-full"
                alt="sonhe video"
                fluid={viajeAustraliaImg.childImageSharp.fluid}
              />
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Austrália
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Ensino Superior e Idiomas
            </p>
            <Link
              className="text-indigo-500 inline-flex items-center mt-3 underline hover:no-underline"
              to="/australia"
            >
              Saiba Mais
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </Link>
          </div>
        </div>
      </div>

      <div className="container px-5 py-2 mx-auto">
        <h2 className="title-font sm:text-4xl text-3xl my-10 font-medium text-gray-900 font-family-louize tracking-tighter">
          Universidades <span className="text-underline">em destaque</span>
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-1 sm:gap-4 xl:mx-20">
        <Card
          name="Tacoma Community College"
          img={viajeSchoolTacomaImg}
          className="p-4 mb-6 rounded-lg shadow-lg bg-blue-300"
          to="/tacoma-community-college"
        />

        <Card
          name="University Canada West"
          img={viajeSchoolCanadaImg}
          className="p-4 mb-6 rounded-lg shadow-lg bg-red-300"
          to="/university-canada-west"
        />

        <Card
          name="Berlin School of Business & Innovation"
          img={viajeSchoolBerlinImg}
          className="p-4 mb-6 rounded-lg shadow-lg bg-gray-300"
        />

        <Card
          name="University of Idaho"
          img={viajeSchoolIdahoImg}
          className="p-4 mb-6 rounded-lg shadow-lg bg-yellow-300"
        />
      </div>
    </section>
  );
};

const Realize = ({
  data: { realizeViajarImg, realizeIdiomasImg, realizeEnsinoImg }
}) => {
  const CardR = ({ name, img, className, to }) => (
    <Link to={to ? to : "#"}>
      <div className={className}>
        <div className="rounded-lg h-64 overflow-hidden">
          <Img
            className="object-cover object-center h-full w-64"
            alt="sonhe video"
            fluid={img.childImageSharp.fluid}
          />
        </div>
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
          {name}
        </h2>
      </div>
    </Link>
  );

  return (
    <section className="text-gray-700 body-font bg-red-100">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="flex w-full mb-20 flex-wrap">
          <h2 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter text-underline">
            Realize
          </h2>
          <p className="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">
            Não somos feitos somente de sonhos. Para que ele se realize é
            necessário um planejamento. Qual o país do seu intercâmbio? Quantos
            meses ou anos você quer ficar? Deseja imigrar? Nós somos a ponte que
            te leva a realizar os seus objetos, além disso, dispomos o melhor
            que temos para te ajudar a realizar o seu sonho planejando com
            responsabilidade. Essa é a nossa missão e nós amamos o que fazemos!
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mx-auto">
          <CardR
            name="Viajar"
            img={realizeViajarImg}
            className="p-4 mb-6 rounded-lg shadow-lg bg-blue-300"
            to="/destinos"
          />

          <CardR
            name="Intercâmbio"
            img={realizeIdiomasImg}
            className="p-4 mb-6 rounded-lg shadow-lg bg-red-300"
            to="/destinos"
          />

          <CardR
            name="Graduação"
            img={realizeEnsinoImg}
            className="p-4 mb-6 rounded-lg shadow-lg bg-gray-300"
            to="/destinos"
          />
        </div>
      </div>
    </section>
  );
};

const IndexPage = ({ data }) => {
  const posts = data.allWpPost.edges.map(({ node }) => node);
  // const tags = data.allWpTag.edges.map(({ node }) => node);
  // const slides = get(data, "slider1", []);
  return (
    <Layout>
      <SEO />

      {/* <Hero {...hero} /> */}

      <Slider slides={[data.slider2]} />

      {/* <SearchCourse className="bg-blue-100" /> */}

      <Sonhe img={data.sonheVideoImg} />

      <Viaje data={data} />

      <Realize data={data} />

      <Testimonials />

      <section className="text-gray-700 body-font bg-indigo-100">
        <div className="container px-5 py-4 mx-auto">
          <h1 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
            O que você <span className="text-underline">procura?</span>
          </h1>
          <div className="grid sm:grid-col-2 lg:grid-cols-3 gap-2 mx-auto">
            {posts.map((node, i) => (
              <Card key={node.slug} {...node} className="w-full" />
            ))}
          </div>
        </div>
      </section>

      <WhatsappButton />
    </Layout>
  );
};

export const pageQuery = graphql`
  fragment PageImage on File {
    childImageSharp {
      fluid(quality: 90, maxWidth: 1800) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }

  query {
    sonheVideoImg: file(name: { eq: "sonhe-video" }) {
      ...PageImage
    }

    viajeAustraliaImg: file(name: { eq: "viaje-australia" }) {
      ...PageImage
    }

    viajeCanadaImg: file(name: { eq: "viaje-canada" }) {
      ...PageImage
    }

    viajeIrlandaImg: file(name: { eq: "viaje-irlanda" }) {
      ...PageImage
    }

    viajeSchoolBerlinImg: file(name: { eq: "viaje-school-berlin" }) {
      ...PageImage
    }

    viajeSchoolCanadaImg: file(name: { eq: "viaje-school-canada-west" }) {
      ...PageImage
    }

    viajeSchoolIdahoImg: file(name: { eq: "viaje-school-idaho" }) {
      ...PageImage
    }

    viajeSchoolTacomaImg: file(name: { eq: "viaje-school-tacoma" }) {
      ...PageImage
    }

    realizeViajarImg: file(name: { eq: "realize-viajar" }) {
      ...PageImage
    }

    realizeIdiomasImg: file(name: { eq: "realize-idiomas" }) {
      ...PageImage
    }

    realizeEnsinoImg: file(name: { eq: "realize-ensino-superior" }) {
      ...PageImage
    }

    # slider1: file(name: { eq: "slider1" }) {
    #   ...PageImage
    # }

    slider2: file(name: { eq: "slider2" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    site {
      siteMetadata {
        title
        siteName
        description
      }
    }

    # blog {
    #   mediaItems(where: { search: "slider" }) {
    #     nodes {
    #       id
    #       link
    #       slug
    #       sourceUrl
    #     }
    #   }
    # }

    allWpTag {
      edges {
        node {
          databaseId
          id
          name
          link
          slug
        }
      }
    }
    allWpPost(limit: 6) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          excerpt
          # author {
          #   node {
          #     name
          #     avatar {
          #       url
          #     }
          #   }
          # }
          # tags {
          #   nodes {
          #     name
          #     slug
          #     link
          #   }
          # }
          categories {
            nodes {
              name
              slug
              link
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
