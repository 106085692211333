import React, { useState } from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery, Link } from "gatsby";

const Hero = ({ title, featured_media, slug, author, date, categories }) => {
  const { allFile } = useStaticQuery(graphql`
    fragment PageImage on File {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    query TestimonialsQuery {
      allFile(
        filter: { name: { regex: "/depo/" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          relativePath
          relativeDirectory
          name
          ...PageImage
        }
      }
    }
  `);

  const data = [
    {
      name: "Bruno Jungmann",
      image: "depo1",
      text:
        "Já tive outra experiência de morar fora, e por isso digo com autoridade, que a Dreams é exemplar. Os sócios e toda a equipe é muito atenciosa, personalizando o atendimento e sempre disponíveis a resolver qualquer problema. Devido a isso, acabam se tornando amigos de todos os clientes, como foi o meu caso. Um novo formato de fazer intercâmbio, simples, eficiente e econômico. Parabéns!"
    },

    {
      name: "Douglas Martins",
      image: "depo2",
      text:
        "Olá pessoal Bom dia, Vim aqui na página da Dream para agradecer tudo o que a agência fez por mim. Acho que uma das dificuldades que as pessoas têm em algumas regiões é a falta de agencias físicas, e por muitas das vezes temos que fazer tudo via internet (Facebook, Skype, Whatsapp). Enfim, todo esse processo acaba gerando uma pequena insegurança quanto a empresa, quem já fechou com eles, se já gostaram dos serviços. Então vim aqui para agradecer pessoalmente, desde o primeiro contato com eles, já percebi que eles eram diferenciados e não me ofereceram mais um pacote de intercambio e sim o que eu estava procurando... Isso foi um diferencial muito grande, aqui eles prezam mesmo pelo cliente. Obrigado Gian e toda a equipe da Dreams que eu não pude conhecer pessoalmente mas que me ajudaram nessa experiencia unica da vida"
    },

    {
      name: "Bruno de Lavor",
      image: "depo3",
      text:
        "Eu já tinha experiência em um intercâmbio anteriormente. Quando decidi fazer o segundo intercâmbio já tinha definido que iria para Toronto. Cotei em algumas empresas mas depois que eu entrei na Dreams Intercâmbios fechei. A empresa passa muita confiança e além de ajudar, te deixam bem à vontade para escolher. Além do preço que é ótimo!"
    },

    {
      name: "Edvaldo Rocha",
      image: "depo4",
      text:
        "Realizado. Esta é a palavra que define o momento quando voltei de Londres. Um sonho é construído por muitos anos, planejado por meses, porém aquele momento único de felicidade chama-se Realização. Obrigado à Dreams Intercâmbios por tornar este sonho em realidade."
    },

    {
      name: "Guilherme Aquino",
      image: "depo5",
      text:
        "Quando pesquisei sobre a Austrália, sobre a qualidade de vida e a beleza natural do país decidi que com a Dreams Intercâmbios, a oportunidade que tanto esperava tinha surgido. Foi uma oportunidade única na minha vida, da qual sinto orgulho de ter a certeza de que fiz um bom negocio não só escolhendo o destino mas também a empresa que me proporcionou isso."
    },

    {
      name: "Bruna Luiza",
      image: "depo6",
      text:
        "Nada se compara a vida no exterior. Principalmente quando proporcionado por uma empresa de seriedade que dedica sua atenção na maior parte do seu tempo aos seus passageiros que, a cada semana, embarcam para seus diversos destinos. Com o intuito de ter essa experiência de vida de intercambista, tão sonhada por muitos e realizada pela Dreams Intercâmbios, deixo aqui meu depoimento dessa viagem maravilhosa. Passei os maravilhosos 4 meses em Orlando na Florida. Hoje, agradeço a Dreams que me empurrou para a vida, me dando essa oportunidade de morar fora, abrindo meus olhos para outras escolhas que pude ter."
    },

    {
      name: "Renata Porto",
      image: "depo7",
      text:
        "Quando iniciei a busca por uma agência de intercâmbio para realizar o meu sonho de estudar fora do país ouvi muitos comentários positivos sobre a Dreams. Resolvi fazer uma visita na agência para conhecer melhor a equipe e realmente ela é show! Hoje tenho uma certeza, a Dreams Intercâmbios foi peça fundamental para meu intercâmbio, tudo ocorreu perfeito, a minha escola é maravilhosa e devo tudo isso a vocês que sempre me deram uma super assistência. Tudo que eu tenho pra falar é MUITO OBRIGADA, meu intercâmbio pra Irlanda não teria sido tão perfeito se não fossem vocês, sempre profissionais, atenciosos e prestativos. Vocês viraram referência de intercâmbio pra mim, indico para qualquer pessoa sem sombra de dúvidas!"
    },

    {
      name: "Thiago Nunes",
      image: "depo8",
      text:
        "A Dreams foi essencial para que meu intercâmbio fosse um sucesso. Com eles, pude realizar todo o meu planejamento, desde onde iria estudar, morar e viajar. Passei dois meses em Londres e só tenho as melhores lembranças. Meu intercâmbio me fez crescer profissionalmente e pessoalmente, além de melhorar meu inglês. A Dreams é TOP."
    }
  ];

  let [show, setShow] = useState(0);

  return (
    <>
      <div className="antialiased sans-serif bg-pink-100 text-gray-600">
        <div className="container mx-auto flex flex-col md:flex-row shadow-sm overflow-hidden">
          <div className="relative w-full py-2 md:py-24 bg-indigo-700 md:w-1/2 flex flex-col item-center justify-center">
            <div className="absolute top-0 left-0 z-10 grid-indigo w-16 h-16 md:w-40 md:h-40 md:ml-20 md:mt-24"></div>

            <div className="relative text-2xl md:text-5xl py-2 px-6 md:py-6 md:px-1 md:w-64 md:mx-auto text-indigo-100 font-semibold leading-tight tracking-tight mb-0 z-20">
              <span className="md:block">Conheça</span>
              <span className="md-block">quem</span>
              <span className="block"> já #REALIZOU!</span>
            </div>

            <div className="absolute right-0 bottom-0 mr-4 mb-4 block z-50">
              <button
                className="rounded-l-full border-r bg-gray-100 text-gray-500 focus:outline-none hover:text-indigo-500 font-bold w-12 h-10"
                onClick={() => {
                  setShow(show === data.length ? data.length - 1 : show--);
                }}
              >
                &#8592;
              </button>
              <button
                className="rounded-r-full bg-gray-100 text-gray-500 focus:outline-none hover:text-indigo-500 font-bold w-12 h-10"
                onClick={() => {
                  setShow(show === data.length ? 0 : show++);
                }}
              >
                &#8594;
              </button>
            </div>
          </div>

          <div className="bg-gray-100 md:w-1/2">
            <div className="flex flex-col h-full relative">
              <div className="absolute top-0 left-0 mt-3 ml-4 md:mt-5 md:ml-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-indigo-200 fill-current w-12 h-12 md:w-16 md:h-16"
                  viewBox="0 0 24 24"
                >
                  <path d="M6.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L9.758 4.03c0 0-.218.052-.597.144C8.97 4.222 8.737 4.278 8.472 4.345c-.271.05-.56.187-.882.312C7.272 4.799 6.904 4.895 6.562 5.123c-.344.218-.741.4-1.091.692C5.132 6.116 4.723 6.377 4.421 6.76c-.33.358-.656.734-.909 1.162C3.219 8.33 3.02 8.778 2.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C2.535 17.474 4.338 19 6.5 19c2.485 0 4.5-2.015 4.5-4.5S8.985 10 6.5 10zM17.5 10c-.223 0-.437.034-.65.065.069-.232.14-.468.254-.68.114-.308.292-.575.469-.844.148-.291.409-.488.601-.737.201-.242.475-.403.692-.604.213-.21.492-.315.714-.463.232-.133.434-.28.65-.35.208-.086.39-.16.539-.222.302-.125.474-.197.474-.197L20.758 4.03c0 0-.218.052-.597.144-.191.048-.424.104-.689.171-.271.05-.56.187-.882.312-.317.143-.686.238-1.028.467-.344.218-.741.4-1.091.692-.339.301-.748.562-1.05.944-.33.358-.656.734-.909 1.162C14.219 8.33 14.02 8.778 13.81 9.221c-.19.443-.343.896-.468 1.336-.237.882-.343 1.72-.384 2.437-.034.718-.014 1.315.028 1.747.015.204.043.402.063.539.017.109.025.168.025.168l.026-.006C13.535 17.474 15.338 19 17.5 19c2.485 0 4.5-2.015 4.5-4.5S19.985 10 17.5 10z" />
                </svg>
              </div>

              <div className="h-full relative z-10">
                {data.map((item, i) => (
                  <div key={i} className={show === i ? "block" : "hidden"}>
                    <p className="text-gray-600 serif font-normal italic px-6 py-6 md:px-16 md:py-10 text-sm md:text-lg">
                      {item.text}
                    </p>
                  </div>
                ))}
              </div>

              <div className="flex justify-center px-6 pt-2 pb-6 md:py-6">
                {data.map((item, i) => (
                  <div
                    key={i}
                    className={
                      show === i ? "block text-center" : "hidden text-center"
                    }
                  >
                    <div className="text-gray-500 text-xs md:text-sm truncate">
                      <Img
                        className="rounded-lg"
                        alt="depo"
                        fluid={allFile.nodes[i].childImageSharp.fluid}
                      />
                    </div>
                    <div className="text-sm md:text-base font-bold text-gray-700 leading-tight">
                      {item.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
